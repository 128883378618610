import React, { FunctionComponent, SetStateAction, Dispatch, useMemo } from "react";
import {
  Stack,
  Text,
  useColorModeValue,
  useTheme,
  Box,
} from "@chakra-ui/react";
import { curveStepAfter } from "d3";

import Chart, { Area, Line } from "scenes/site/components/chart";
import { IPlotData } from "scenes/site/components/chart/types";

import { IDayDef, IScheduleDay, IScheduleMoment } from "./types";
import { fillData } from "./utils"


interface IActiveDayState {
  value: number;
  set: Dispatch<SetStateAction<number>>;
}

interface IProps {
  isActive: boolean;
  activeDay:IActiveDayState;
  dayDef: IDayDef;
  index: number;
  dayState: IScheduleDay;
  dataDomain: [number, number];
  noSuggestions: boolean;
}


const DayTab: FunctionComponent<IProps> = ({
  isActive,
  activeDay,
  index,
  dayDef,
  dayState,
  dataDomain,
  noSuggestions,
}) => {
  const theme = useTheme();
  const tc = theme.colors;

  const strokeWidth = 2;
  const lineHeight = 70;

  const useSuggested = useMemo<boolean>(() => !dayState.moments.length && !noSuggestions, [dayState.moments, noSuggestions]);
  const data = useMemo<IPlotData[]>(() => (useSuggested ? dayState.suggested : dayState.moments).map((value: IScheduleMoment) => ({
    x: value.hour * 60 + value.minute,
    y: value.energy,
    y0: NaN,
  })), [useSuggested, dayState.suggested, dayState.moments]);
  const dataSpecs = useMemo(() => ({
    domainX: [0, 24 * 60],
    domainY: dataDomain,
    offsetX: [strokeWidth / 2, strokeWidth / 2],
    offsetY: [strokeWidth / 2, strokeWidth / 2],
    strokeWidth: strokeWidth,
    data: fillData(data),
  }), [strokeWidth, data, dataDomain]);

  const bgColor = useColorModeValue("gray.100", "gray.900");
  const fgColor = useColorModeValue("white", "black");
  const momentTraceStrokeColor = useColorModeValue(tc.gray["700"], tc.gray["300"]);
  const momentTraceFillColor = useColorModeValue(tc.gray["300"], tc.gray["700"]);
  const suggestedTraceStrokeColor = tc.enposol["500"];
  const suggestedTraceFillColor = useColorModeValue(tc.enposol["100"], tc.enposol["600"]);

  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent="flex-end"
      alignSelf={isActive ? "flex-end" : "stretch"}
      h={lineHeight}
      py={"20px"}
      bg={isActive ? useSuggested ? "enposol.500" : "gray.500" : bgColor}
      borderColor={useColorModeValue(tc.white, tc.gray["950"])}
      color={isActive ? fgColor : undefined}
      fontWeight={isActive ? "bold" : undefined}
      cursor={isActive ? undefined : "pointer"}
      onClick={() => activeDay.set(index)}
    >
      <Text
        flex={1}
        px={3}
      >
        {dayDef.abbr}
      </Text>
      <Box hidden={activeDay.value === index}>
        <Chart
          curve={curveStepAfter}
          width={150}
          height={lineHeight}
        >
          <Area {...dataSpecs} fill={useSuggested ? suggestedTraceFillColor : momentTraceFillColor} />
          <Line {...dataSpecs} stroke={useSuggested ? suggestedTraceStrokeColor : momentTraceStrokeColor} />
        </Chart>
      </Box>
    </Stack>
  );
};

export default DayTab;
