import React, { FunctionComponent, useContext, SVGAttributes } from "react";
import { area, extent } from "d3";

import { ChartContext, makeScale } from "..";
import { PathBase } from "../base";
import { IDataProps, IPlotData, IShapeProps } from "../types"


interface IProps extends IDataProps, IShapeProps, SVGAttributes<SVGPathElement> {
  data: IPlotData[];
  domainX?: number[];
  domainY?: number[];
  rangeX?: number[];
  rangeY?: number[];
  offsetX?: number[];
  offsetY?: number[];
}


const Area: FunctionComponent<IProps> = ({
  data,
  domainX = extent<number>(data.map(d => d.x)) as number[],
  domainY = extent<number>(data.map(d => d.y)) as number[],
  rangeX,
  rangeY,
  offsetX,
  offsetY,
  curve,
  strokeWidth = 1,
  ...rest
}) => {
  const context = useContext(ChartContext);
  const scaleX = makeScale("x", context, domainX, rangeX, offsetX);
  const scaleY = makeScale("y", context, domainY, rangeY, offsetY);

  const generator = area<IPlotData>()
                    .defined(d => d.y !== null)
                    .x(d => scaleX(d.x))
                    .y0(d => scaleY(isNaN(d.y0) ? domainY[0] : d.y0))
                    .y1(d => scaleY(isNaN(d.y) ? 0 : d.y))
                    .curve(curve || context.curve)
                    ;

  return (
    <>
      <PathBase
        className = "area"
        {...{
          generator,
          data,
          scaleX,
          scaleY,
          strokeWidth,
        }}
        {...rest}
      />
    </>
  )
}

export default Area;
