import React, { FunctionComponent, useMemo } from "react";
import { useIntl } from "react-intl";
// import { useNavigate } from "react-router-dom";

import StatsTable from "components/stats-table";
import BaseCard, { cardLabel } from "./BaseCard";
import { SiteConfig_siteConfig_dashboardConf_components } from "graphql/generated";
import CHPState from "../../chp/CHPState";
import CHPSwitchState from "../../chp/CHPSwitchState";
import { useNavigate } from "react-router-dom";
// import { DashboardMeasurement_dashboardMeasurement_chp } from "graphql/generated";


interface IProps {
  // data: DashboardMeasurement_dashboardMeasurement_chp | undefined;
  data: any;
  baseUrl: string;
  config: SiteConfig_siteConfig_dashboardConf_components;
}


const CHPCard: FunctionComponent<IProps> = ({ data, baseUrl, config }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const imageUrl = useMemo<string>(() => {
    switch (config.uiVersion) {
      case "neufe": return "/images/svg/genset.svg";
      default: return "/images/svg/kogeneracni-jednotka.svg";
    }
  }, [config.uiVersion]);

  const statsTableRows = useMemo(() => {
    switch(config.uiVersion) {
      case "neufe":
        return [
          {
            label: intl.formatMessage({
              id: "dashboard__chp__kw",
              defaultMessage: "Active power",
            }),
            unit: "kW",
            decimals: 2,
            // type: "number",
            value: data?.active_power,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__temp_coolant",
              defaultMessage: "Coolant",
            }),
            unit: "°C",
            decimals: 1,
            // type: "number",
            value: data?.temp1,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__temp_output_water",
              defaultMessage: "Output water",
            }),
            unit: "°C",
            decimals: 1,
            // type: "number",
            value: data?.temp2,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__switch_status",
              defaultMessage: "Switch",
            }),
            value: <CHPSwitchState state={data?.m3h} />,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__status",
              defaultMessage: "Status",
            }),
            value: <CHPState state={data?.status} />,
          },
        ]
      default:
        return [
          {
            label: intl.formatMessage({
              id: "dashboard__chp__kw",
              defaultMessage: "Active power",
            }),
            unit: "kW",
            decimals: 2,
            // type: "number",
            value: data?.active_power,
          },
          // {
          //   label: intl.formatMessage({
          //     id: "dashboard__chp__kvar",
          //     defaultMessage: "kVAR",
          //   }),
          //   //   unit: "%",
          //   decimals: 2,
          //   // type: "number",
          //   value: data?.kvar,
          // },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__temp_coolant",
              defaultMessage: "Coolant",
            }),
            unit: "°C",
            decimals: 1,
            // type: "number",
            value: data?.temp1,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__temp_output_water",
              defaultMessage: "Output water",
            }),
            unit: "°C",
            decimals: 1,
            // type: "number",
            value: data?.temp2,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__gas_flow",
              defaultMessage: "Gas flow",
            }),
            unit: "bar",
            decimals: 1,
            // type: "number",
            value: data?.m3h,
          },
          {
            label: intl.formatMessage({
              id: "dashboard__chp__status",
              defaultMessage: "Status",
            }),
            //   unit: "°C",
            decimals: 0,
            // type: "number",
            value: data?.status,
          },
        ]
    }
  }, [data, config.uiVersion, intl])

  return (
    <BaseCard
      title={intl.formatMessage({
        id: "dashboard__chp____title",
        defaultMessage: `{count, plural,
          one {CHP}
          other {CHPs}
        } {label}`,
      }, {
        ...cardLabel(config),
      })}
      control={data?.chp_control}
      image={imageUrl}
      onClick={() => {
        navigate(`${baseUrl}/chp`);
      }}
    >
        <StatsTable
          inline
          rows={statsTableRows}
        />
    </BaseCard>
  );
};

export default CHPCard;
