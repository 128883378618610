import React, { FunctionComponent, useRef, SVGAttributes, useCallback } from "react";
import { select, ScaleContinuousNumeric } from "d3";

import { DatumMouseEvent } from "scenes/site/components/chart/types";

import { IPlotData, SvgShape } from "../types"


interface IProps extends SVGAttributes<SVGPathElement> {
  data: IPlotData[];
  scaleX: ScaleContinuousNumeric<number, number>;
  scaleY: ScaleContinuousNumeric<number, number>;
  shape?: SvgShape;
}


const ShapeBase: FunctionComponent<IProps> = ({
  data,
  scaleX,
  scaleY,
  shape = "circle",
  className = `shape-${shape}`,
  stroke = null,
  strokeWidth = 1,
  r = 1.5,
  fill = "none",
  onMouseDown,
  onDoubleClick,
  ...rest
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const svgEl = select(svgRef.current);

  const handleMouseDown = useCallback((e: DatumMouseEvent<SVGPathElement>, d: IPlotData) => {
    if (onMouseDown) {
      e.stopPropagation();
      e.d = d;
      onMouseDown(e);
    }
  }, [onMouseDown]);

  svgEl
    .attr("class", className)
    .attr("fill", fill)
    .attr("stroke", stroke)
    .attr("stroke-width", strokeWidth)

    .selectAll<SVGCircleElement, IPlotData>(`.${className}`)
    .data(data.filter(d => d.y !== null))
    .join(shape)
      .attr("class", className)
      .attr("r", r)
      .attr("cx", d => scaleX(d.x))
      .attr("cy", d => scaleY(d.y))
      .on("mousedown", handleMouseDown)
      .on("click", (e, d) => onDoubleClick && (e.d = d) && onDoubleClick(e))
      // .transition()
      //   .duration(2000)
      ;

  return (
    <>
      <g ref={svgRef} {...rest} />
    </>
  )
}

export default ShapeBase;
