import React, { FunctionComponent, useCallback, useMemo } from "react";

import {
  TableCellProps,
  Td,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

import { JSONValue, SharedProps } from "./types";
import { format } from "d3";
import { useIntl } from "react-intl";


interface IProps extends TableCellProps, SharedProps {
  value: JSONValue;
  correction?: number;
  label: string;
  units?: string;
  isCurrentHour?: boolean;
  multiplier?: number;
}


const RowValue: FunctionComponent<IProps> = ({
  value,
  correction = NaN,
  label,
  units = "",
  multiplier = 1,
  cellWidth,
  cellPaddingX,
  isCurrentHour,
  ...rest
}) => {
  const intl = useIntl();
  const correctedValue = useMemo<number>(() => (isNaN(correction) ? value : correction) as number, [value, correction]);

  const trueMessage = useMemo(() => intl.formatMessage({
    id: "site__trading__row_value__true",
    defaultMessage: "Yes",
  }), [intl]);
  const falseMessage = useMemo(() => intl.formatMessage({
    id: "site__trading__row_value__false",
    defaultMessage: "No",
  }), [intl]);
  const insteadMessage = useMemo(() => intl.formatMessage({
    id: "site__trading__row_value__instead",
    defaultMessage: "instead",
  }), [intl]);

  const valueColor = useCallback((value: JSONValue): string | undefined => {
    if (value !== undefined && value < 0) return "red.500";
  }, []);

  const valueWeight = useCallback((value: JSONValue): string | undefined => {
    return isCurrentHour ? "bold" : "regular";
  }, [isCurrentHour]);

  const reasonablePrecision = useCallback((value: number): number | string => {
    const abs = Math.abs(value);

    if (abs === 0 ) return value;
    else if (abs < 10) return format(".1f")(value);
    else if (abs > 1000000) return `${ reasonablePrecision(value / 1000000)}M`;
    else if (abs > 1000) return `${ reasonablePrecision(value / 1000)}k`;
    else return Math.round(value);
  }, []);

  const formattedValue = useCallback((value: any):string => {
    switch(typeof(value)) {
      case "number":  return `${(value * multiplier).toFixed(3)} ${units}`.trim();
      case "boolean": return `${value ? trueMessage : falseMessage}`;
      default:        return `${value} ${units}`.trim();
    }
  }, [multiplier, units, trueMessage, falseMessage]);

  const tooltip = useCallback((): string => {
    if (value === null) return "";

    const lbl = label.replaceAll(/<[^>]+>/g, ""); // remove eventual HTML tags

    switch(typeof(value)) {
      case "boolean": return `${lbl}? ${formattedValue(value)}`;
      default:        return `${lbl}: ${isNaN(correction) || correction === value ? formattedValue(value) : `${formattedValue(correction)} ${insteadMessage} ${formattedValue(value)}`}`;
    }
  }, [label, value, correction, formattedValue, insteadMessage]);

  const pageBackgroundColor = useColorModeValue("white", "gray.900");
  const booleanTrueColor = useColorModeValue("enposol.500", "enposol.500");
  const booleanFalseColor = useColorModeValue("gray.200", "gray.800");

  return (
    <>
      <Td
        title={tooltip()}
        textAlign="right"
        color={valueColor(correctedValue)}
        fontWeight={valueWeight(correctedValue)}
        fontSize={"xs"}
        px={`${cellPaddingX}px`}
        py={0}
        verticalAlign={typeof(value) == "boolean" ? "middle" : "top"}
        {...rest}
      >
        { typeof(value) == "boolean" && (
          <Box width={"100%"} height={2.5} borderTopLeftRadius={3} borderRightWidth={1} borderColor={pageBackgroundColor}
            bg={value ? booleanTrueColor : booleanFalseColor}
          ></Box>
        ) }
        { typeof(value) == "number" && (
          <Box width={cellWidth} whiteSpace="pre" overflow="hidden">
            { reasonablePrecision(correctedValue * multiplier) }
          </Box>
        ) }
      </Td>
    </>
  )
}

export default RowValue;
