import {
  ScaleContinuousNumeric,
  scaleLinear,
} from "d3";

import {
  IChartContext,
  IStageContext,
  IPlotData,
  ScaleAxis,
} from "./types";

export const makeScale = (axis: ScaleAxis, context: IChartContext | IStageContext, domain: number[], range?: number[], offset?: number[]): ScaleContinuousNumeric<number, number> => {
  const isX = axis === "x";
  const r = range || (isX ? context.rangeX : context.rangeY);
  const o = offset || (isX ? context.offsetX : context.offsetY);

  const offsetRange = [
    r[0] + o[0],
    r[1] - o[1],
  ];
  if (!isX) offsetRange.reverse();

  return scaleLinear<number, number>().domain(domain).rangeRound(offsetRange);
}

export const plotData = (
  primaries: number[], // primary dataset is what gets displayed
  secondaries: number[] = [], // secondary dataset is what is used as a base for the primary
  offsetX = 0, // added to X coordinate
  gains = false, // only secondary values greater than primary are displayed
  losses = false, // only secondary values lower than primary are displayed
): IPlotData[] => {
  return primaries.map((value: number, hour: number) => {
    const primary = value as number;

    if (secondaries.length) {
      const secondary = secondaries[hour] as number;

      return {
        x: hour + offsetX,
        y: gains ? Math.max(primary, secondary) : secondary,
        y0: losses ? Math.min(primary, secondary) : secondary,
      }
    } else {
      return {
        x: hour + offsetX,
        y: primary,
        y0: 0,
      }
    }
  })
};

export const plotBaseData = (
  primaries: number[], // primary dataset is what gets displayed
  secondaries: number[], // secondary dataset is what is used as a base for the primary
  offset = 0, // added to X coordinate
): IPlotData[] => {
  return primaries.map((value: number, hour: number) => {
    const primary = value as number;
    const secondary = secondaries[hour] as number;

    return {
      x: hour + offset,
      y: isNaN(secondary) ? primary : Math.max(primary, secondary),
      y0: NaN,
    }
  })
};
