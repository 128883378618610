import React, { FunctionComponent, useState, useRef, useEffect, useMemo } from "react";
import assert from "assert";
import { debounce } from "lodash";
import { extent, interpolateArray  } from "d3";

import {
  Code,
  Td,
  Th,
  Tr,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";

import { SharedProps } from "./types";

import { interleaveArrays } from "utils";
import Chart, { Area, FunnyAxis, Line, plotData, plotBaseData } from "scenes/site/components/chart";
import { IPlotData, IDimensions } from "scenes/site/components/chart/types";


interface IProps extends SharedProps {
  label: string;
  units?: string;
  values: number[];
  corrections: number[];
  colors: string[];
  nowHourIndex: number;
}


const ValuesChartRow: FunctionComponent<IProps> = ({
  label,
  units,
  values: _values,
  corrections: _corrections = [],
  colors = [],
  cellWidth,
  cellPaddingX,
  nowHourIndex,
  ...rest
}) => {
  const theme = useTheme();
  const tc = theme.colors;
  const containerRef = useRef<HTMLTableCellElement>(null);
  const [windowSize, setWindowSize] = useState<IDimensions>({ width: 0, height: 0 });
  const cellAdjust = 5;
  const values = useMemo(() => interleaveArrays(_values, interpolateArray(_values, [..._values.slice(1)])(0.5)), [_values]);
  const corrections = useMemo(() => interleaveArrays(_corrections, interpolateArray(_corrections, [..._corrections.slice(1)])(0.5)).slice(0, -1), [_corrections]);
  const rawData = useMemo<IPlotData[]>(() => plotData(values), [values]);
  const hoursData = useMemo<IPlotData[]>(() => plotData(values.filter((_, i) => i % 2 === 0)), [values]);
  const pastData = useMemo<IPlotData[]>(() => plotData(corrections), [corrections]);
  const correctedPastData = useMemo<IPlotData[]>(() => plotBaseData(corrections, values), [values, corrections]);
  const predictedPastData = useMemo<IPlotData[]>(() => plotData(values.slice(0, corrections.length)), [values, corrections]);
  const predictedFutureData = useMemo<IPlotData[]>(() => plotBaseData(corrections.length ? values.slice(corrections.length - 1) : values, [], corrections.length ? corrections.length - 1 : 0), [values, corrections]);
  const gainData = useMemo<IPlotData[]>(() => plotData(corrections, values, 0, true, false), [values, corrections]);
  const lossData = useMemo<IPlotData[]>(() => plotData(corrections, values, 0, false, true), [values, corrections]);
  const dataDomain = useMemo<number[]>(() => {
    const domain = extent<number>([
      ...rawData.map(d => d.y),
      ...gainData.map(d => d.y),
      ...gainData.map(d => d.y0),
      ...lossData.map(d => d.y),
      ...lossData.map(d => d.y0),
    ]) as number[];

    return [domain[0] - 0.1, domain[1] + 0.1];
  }, [rawData, gainData, lossData]);
  const timeDomain = useMemo<[number, number]>(() => [0, values.length - 2], [values]);
  const dataLength = useMemo<number>(() => values.filter(value => value !== null).length, [values]);
  const zeroLineData = useMemo<IPlotData[]>(() => [
    {x: 0, y: 0, y0: NaN},
    {x: dataLength - 1, y: 0, y0: NaN},
  ], [dataLength]);
  const currentHourData = useMemo<IPlotData[]>(() => [
    {x: nowHourIndex * 2, y: dataDomain[0], y0: dataDomain[0]},
    {x: nowHourIndex * 2, y: dataDomain[1], y0: dataDomain[1]},
  ], [dataDomain, nowHourIndex]);
  const [lossColor, gainColor] = colors.map(d => d === "transparent" ? "#F30" : d);

  // Resolve `windowSize` state and update it on window size change.
  //
  useEffect(() => {
    const containerEl = containerRef.current;
    assert(containerEl);

    const resizeWindow = () => {
      setWindowSize({
        width: containerEl.offsetWidth,
        height: containerEl.offsetHeight,
      });
    };
    resizeWindow();

    const debouncedResizeWindow = debounce(resizeWindow, 5, { leading: true, trailing: true });

    window.addEventListener('resize', debouncedResizeWindow);

    return () => {
      window.removeEventListener('resize', debouncedResizeWindow);
    }
  }, []);

  return (
    <>
      <Tr>
        <Th verticalAlign="bottom" position="sticky" left={0} bg={useColorModeValue("white", "gray.950")}>
          { units &&
            <Code fontSize={"xs"} colorScheme="yellow">{units}</Code>
          }
        </Th>
        <Td colSpan={values.length} p={0} textAlign="right" ref={containerRef}>
          <Chart
            width={windowSize.width}
            height={30}
            offsetX={[cellWidth - cellPaddingX - cellAdjust, cellPaddingX + cellAdjust]}
            offsetY={[2, 2]}
            {...rest}
          >
            <Area data={correctedPastData} domainX={timeDomain} domainY={dataDomain} fill={useColorModeValue(tc.gray["300"], tc.gray["700"])} />
            <Area data={predictedFutureData} domainX={timeDomain} domainY={dataDomain} fill={useColorModeValue(tc.gray["300"], tc.gray["700"])} />
            <Area data={gainData} domainX={timeDomain} domainY={dataDomain} fill={gainColor} opacity={.3} />
            <Area data={lossData} domainX={timeDomain} domainY={dataDomain} fill={lossColor} opacity={.3} />
            <Line data={predictedFutureData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["700"], tc.gray["300"])} strokeWidth={1} />
            <Line data={pastData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["700"], tc.gray["300"])} strokeWidth={1} />
            <Line data={predictedPastData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["700"], tc.gray["300"])} strokeDasharray={"3 2"} strokeWidth={1} />
            <Line data={zeroLineData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.red["600"], tc.red["400"])} opacity={0.3} />
            <Line data={currentHourData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.white, tc.gray["950"])} strokeWidth={1} opacity={0.5} />
            <Line data={currentHourData} domainX={timeDomain} domainY={dataDomain} stroke={useColorModeValue(tc.gray["950"], tc.white)} strokeDasharray="1" />
            <FunnyAxis data={hoursData} />
          </Chart>
        </Td>
      </Tr>
    </>
  )
}

export default ValuesChartRow;
