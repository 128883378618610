import Chart, { ChartContext } from "./base";
import {
  Area,
  AxisX,
  AxisY,
  FunnyAxis,
  Line,
  Points,
} from "./components";
import {
  makeScale,
  plotData,
  plotBaseData,
} from "./utils";

export {
  // context
  ChartContext,
  // components
  Area,
  Line,
  Points,
  FunnyAxis,
  AxisX,
  AxisY,
  // utils
  makeScale,
  plotData,
  plotBaseData,
}

export default Chart;
