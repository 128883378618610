import React, { FunctionComponent, useMemo } from "react";
import { extent, scaleLinear } from "d3";

import {
  TableRowProps,
  Th,
  Tr,
  Td,
  useColorModeValue,
} from "@chakra-ui/react";

import { JSONArray } from "./types";


interface IProps extends TableRowProps {
  values: JSONArray;
  corrections: number[];
  colors: string[];
}


const ValuesHeatmapRow: FunctionComponent<IProps> = ({
  values,
  corrections = [],
  colors,
  ...rest
}) => {
  const correctedValues = useMemo<number[]>(() => values.map<number>((d, i) => (isNaN(corrections[i]) ? d as number : corrections[i])), [values, corrections])
  const dataDomain = useMemo<number[]>(() => extent<number>(correctedValues.map<number>(d => d)) as number[], [correctedValues]);
  const colorScale = scaleLinear<string, string>().domain(dataDomain).range(colors);

  return (
    <>
      <Tr {...rest}>
        <Th pt={0} position="sticky" left={0} bg={useColorModeValue("white", "gray.950")}/>
        {correctedValues.map((value: number) => <Td background={colorScale(value)} pt={.5} pb={.5} borderRadius={0} /> )}
      </Tr>
    </>
  )
}

export default ValuesHeatmapRow;
