
// Function to interleave members of two given arrays
// Input of [1,2,3] and [A,B,C] results in [1,A,2,B,3,C]
//
export const interleaveArrays = (arr1: number[], arr2: number[]) => {
    const newArr = new Array(arr1.length + arr2.length);

    for (let i = 0, j = 0; i < arr1.length; i++) {
        newArr[j++] = arr1[i];
        newArr[j++] = arr2[i];
    }
    return newArr;
}

